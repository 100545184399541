<template>
  <div class="pending-swift-payment">
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Pending Swift Payment</h3>
            </div>

            <div class="card-body min-vh">
              <div class="search-area">
                 <div class="row justify-content-md-center">
                    <div class="col-sm-12 col-md-2 text-center mb-2">
                      <el-select 
                        v-model="query.filterType" 
                        placeholder="Select"
                        class="form-control-alternative">
                          <el-option label="All" value="all"></el-option>
                          <el-option label="Pending" value="pending"></el-option>
                          <el-option label="Process" value="process"></el-option>
                          <el-option label="Paid" value="paid"></el-option>
                          <el-option label="Cancel" value="cancel"></el-option>
                        </el-select>
                    </div>
                    <div class="col-sm-12 col-md-3 text-center mb-2">
                      <el-date-picker
                        v-model="dateSearch"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        format="yyyy-MM-dd"
                        class="form-control-alternative"
                      >
                      </el-date-picker>
                    </div>

                    <div class="col-sm-12 col-md-2 text-center">
                      <el-button slot="append" type="primary" :disabled="loading" v-loading="loading" icon="el-icon-search" @click="getPendingSwiftPayment()">Search</el-button>
                    </div>
                </div>
              </div>

              <el-table
              size="mini"
                border
                :data="dataTable"
                :loading="loading"
                style="width: 100%">
                <el-table-column
                  label="Date Create"
                  prop="created"
                  header-align="center" 
                  align="center"
                  fixed="left"
                  width="150"
                  >
                </el-table-column>
                <el-table-column
                  label="Payee"
                  prop="name"
                  header-align="center" 
                  align="left"
                  width="250"
                  >
                </el-table-column>
                <el-table-column
                  label="From"
                  prop="from"
                  header-align="center" 
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  label="To"
                  prop="to"
                  header-align="center" 
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  label="Amount"
                  prop="amount"
                  header-align="center" 
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  label="Rate"
                  prop="rate"
                  header-align="center" 
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  label="Fee"
                  prop="fee"
                  header-align="center" 
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  label="Receive"
                  prop="receive"
                  header-align="center" 
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  label="Ref."
                  prop="reference"
                  header-align="center" 
                  align="left"
                  >
                </el-table-column>
                <el-table-column
                  label="Status"
                  prop="status"
                  header-align="center" 
                  align="center"
                  fixed="right"
                  width="100"
                  >
                  <template slot-scope="scope">
                    <el-tag size="mini" :type="formatType[scope.row.status]">{{scope.row.status.toUpperCase()}}</el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import PaymentApi from "@/api/payment";
import Utils from '@/utils'
export default {
  computed:{
    isBusiness() {
      return this.$store.getters.business;
    },
  },
  data(){
    return {
      loading: false,
      dataTable : [],
      dateSearch: [],
      query : {
        startdate: "",
        enddate: "",
        filterType: "all"
      },
      formatType: {
        pending: "warning",
        process: "primary",
        paid: "success",
        cancel: "danger"
      }
    }
  },
  methods:{
    getPendingSwiftPayment(){
      this.loading = true;
      this.query.startdate = Utils.dateFormat(this.dateSearch[0], "YYYY-MM-DD") || Utils.getCurrentDate("YYYY-MM-DD")
      this.query.enddate = Utils.dateFormat(this.dateSearch[1], "YYYY-MM-DD") || Utils.getCurrentDate("YYYY-MM-DD")
      PaymentApi.pendingSwift(this.query).then(({result, data}) => {
        this.loading = false;
        if(result){
          this.dataTable = data.list
        }
      });
    }
  },
  mounted(){
    this.getPendingSwiftPayment()
  }
}
</script>

<style lang="scss">
.pending-swift-payment{
  .search-area{
    margin-bottom: 16px;
  }
  .el-date-editor{
    min-width: 100%;
    .el-range-separator {
      width: 20%;
    }
  }
}
</style>